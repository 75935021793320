import { toast } from 'sonner';
import { useState } from 'react';
import { Flex, Grid, Spinner } from '@radix-ui/themes';

import Input from '../Input';
import Button from '../Button';
import { visitorFormDetails } from '../../data/registerForm';
import {
  checkErrors,
  correctEmiratesId,
  hashValue,
  setEncryptedItem,
} from '../../helper/helper_functions';
import {
  checkDuplicateEntry,
  generateQrCodeAsDataUrl,
} from '../../helper/database_functions';
import { firestore, storageInstance } from '../../services/firebase.config';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';

const VisitorRegistration = () => {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage('');

    const formData = new FormData(e.target);
    var data = Object.fromEntries(formData.entries());

    data.emiratesId = correctEmiratesId(data.emiratesId);

    const error = checkErrors(data.emiratesId, data.phone);
    if (error !== null) {
      setErrorMessage(error);
      setLoading(false);
      return;
    }

    try {
      const hashedId = await hashValue(data.emiratesId);
      const qrCodeBase64 = generateQrCodeAsDataUrl(hashedId);
      const qrCodeRef = ref(storageInstance, `qr_codes/${data.emiratesId}.png`);
      await uploadString(qrCodeRef, qrCodeBase64, 'data_url');
      const qrDownloadUrl = await getDownloadURL(qrCodeRef);

      data = { ...data, imageUrl: qrDownloadUrl, hashValue: hashedId };
      const isDuplicate = await checkDuplicateEntry(data.emiratesId);
      if (isDuplicate) {
        throw new Error('A Registration with this ID already exists');
      }

      await setDoc(
        doc(
          firestore,
          `registrations/${currentYear}/bsf/visitors/registrations`,
          data.emiratesId
        ),
        data
      );
      await emailjs.send(
        'service_t8clwjo',
        'template_5mzllit',
        data,
        'V2Yz1dwEESLY22zXz'
      );
      toast.success('Email sent successfuly');

      setEncryptedItem('id', data.emiratesId);
      setEncryptedItem('email', data.email);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
      toast.error(error?.message);
      setErrorMessage('Registration failed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='w-full'>
      <Flex
        width='100%'
        align='center'
        justify='center'
        direction='column'
        gap={{ initial: '4', md: '6' }}
      >
        <Grid
          width='100%'
          align='center'
          gap={{ initial: '4', md: '6' }}
          columns={{ initial: '1', sm: '2' }}
        >
          {visitorFormDetails.map((field, index) => (
            <Input
              key={index}
              name={field.name}
              type={field.type}
              placeholder={field.label}
              required={field.required === false ? false : true}
            />
          ))}
        </Grid>

        <p className='text-redColor text-sm md:text-md font-semibold'>
          {errorMessage}
        </p>

        <Button
          type='submit'
          title={loading ? <Spinner size='3' /> : 'SUBMIT'}
          className='px-8 md:px-16 w-full md:w-fit'
        />
      </Flex>
    </form>
  );
};

export default VisitorRegistration;
